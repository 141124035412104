import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box, Button, Typography, CircularProgress, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Switch, Divider, InputLabel, MenuItem, FormControl, Select, useMediaQuery } from '@mui/material';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import DownloadUtils from '../../../utils/download-utils';
import UserApi from '../../../rest-api/user-api';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiButton from '../../UIControls/api-button/api-button';
import UploadUtils from '../../../utils/UploadUtils';
import { format } from 'date-fns';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Auth } from 'aws-amplify';
import SuccessDialog from '../../UIControls/api-button/SuccessDialog';
import profilePlaceholder from '../../../assets/logos/profile_placeholder.svg';
import picIcon from '../../../assets/icons/Picture.svg';
import picAllIcon from '../../../assets/icons/Picture_All.svg';
import uploadIcon from '../../../assets/icons/Upload.svg';
import binIcon from '../../../assets/icons/Bin.svg';
import passwordIcon from '../../../assets/icons/Password.svg';
import cookiesIcon from '../../../assets/icons/Cookies.svg';
import subscriptionIcon from '../../../assets/icons/Subscription.svg';
import arrowRight from '../../../assets/icons/Arrow_Right.svg';
import arrowWhiteRight from '../../../assets/icons/Arrow-white_Right.svg';
import GradientBorderButton from '../../UIControls/GradientBorderButton';
import AntSwitch from '../../UIControls/AntSwitch';
import { useUnsavedChanges } from '../UnsavedChangesContext';

function UnsavedChangesModal({ open, onDiscardChanges, onCancel }) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have unsaved changes. Are you sure you want to leave?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onDiscardChanges} color="primary">
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function SettingsScreen() {
  const appStateStore = useContext(StoreContext);
  const { authStore, contentStore, imageStore, userDataStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const idToken = authStore.idToken;
  const [clinicianData, setClinicianData] = useState(null);
  const [clinicImage, setClinicImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [passwordChangeError, setPasswordChangeError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [isVerificationDialogOpen, setIsVerificationDialogOpen] = useState(false);
  const [pendingClinicDataUpdate, setPendingClinicDataUpdate] = useState(null);
  const [isSuccessEmailDialogOpen, setIsSuccessEmailDialogOpen] = useState(false);

  const { hasUnsavedChanges, setHasUnsavedChanges, onConfirmNavigation, onCancelNavigation, showPrompt} = useUnsavedChanges();
  const isMobile = useMediaQuery('(max-width:490px)');

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    if (hasUnsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  
  useEffect(() => {
    fetchClinicianData();
  }, []); 

  useEffect(() => {
    if (clinicianData?.userData?.emailAddress) {
      setNewEmail(clinicianData.userData.emailAddress);
    }
  }, [clinicianData]);

  const fetchClinicianData = async () => {
    setLoading(true);
    try {
      const clinicianDataResponse = await UserApi.getClinicianData(authStore.idToken);
      if (clinicianDataResponse) {
        setClinicianData(clinicianDataResponse);
        console.warn("Clinician data fetched and component state updated successfully.", clinicianDataResponse);
        
        // Initialize formattedData with properties that don't depend on the clinic image
        let formattedData = {
          firstName: clinicianDataResponse.userData.firstName,
          lastName: clinicianDataResponse.userData.lastName,
          emailAddress: clinicianDataResponse.userData.emailAddress,
          mobile: clinicianDataResponse.userData.mobile || '',
          clinicName: clinicianDataResponse.clinicData.clinicName,
          clinicNumber: clinicianDataResponse.clinicData.clinicNumber || '',
          clinicSize: clinicianDataResponse.clinicData.clinicSize || '',
          clinicImageId: '',
          showLogoInHandouts: clinicianDataResponse.showLogoInHandouts,
          handouts: JSON.stringify(clinicianDataResponse.handouts)
        };
  
        if (clinicianDataResponse.clinicData.clinicImageId) {
          const payload = {
            files: [{ method: "get", key: clinicianDataResponse.clinicData.clinicImageId }],
            contentCategory: "clinic_image"
          };
  
          const clinicImageURL = await UserApi.generatedPresignedUrls(authStore.idToken, payload);
          if (clinicImageURL && clinicImageURL.getUrls) {
            console.warn('Clinic image found:' , clinicImageURL);
            setClinicImage(clinicImageURL.getUrls[0].presignedUrl);
            let clinicImageBase64 = await DownloadUtils.downloadImageFromPresignedUrl(clinicImageURL.getUrls[0].presignedUrl);
            // Update clinicImageId in formattedData with the base64 encoded image string
            formattedData.clinicImageId = clinicImageBase64.base64data;
          }
        } else {setClinicImage('')}
        console.warn(formattedData);
        userDataStore.setUserData(formattedData);
      }
    } catch (error) {
      console.error("Failed to fetch clinician data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignout = async () => {
    console.log('handleSignout');
    await authStore.logout();
    await appStateStore.clearUserStores();
    localStorage.clear();
  };

  const handleFileChange = async (e) => {
    if (e.target.files.length > 0) {
        const file = e.target.files[0];

        if (file.size > 2 * 1024 * 1024) {
            alert('File size exceeds the allowed maximum of 2MB. Please select another image.');
            return;
        }

        const objectURL = URL.createObjectURL(file);

        const image = new Image();
        image.src = objectURL;
        image.onload = async () => {
            if (image.width > 700 || image.height > 700) {
                alert('Image dimensions exceed the allowed maximum of 700x700 pixels. Please select another image.');
                URL.revokeObjectURL(objectURL);
            } else {
                setSelectedFile(file);
                await uploadClinicImage(file); 
            }
        };
        image.onerror = () => {
            alert("There was an error loading the image. Please select another image.");
            URL.revokeObjectURL(objectURL);
        };
    }
  };

  const createFileObject = (file) => {
    const dateStr = format(new Date(), 'yyyy-MM-dd');
    const fileNameStr = file.name.replace(/ /g, '-').toLowerCase();
    const extension = file.name.split('.').pop();
    const baseKey = `${clinicianData.clinicData.clinicName.replace(/ /g, '-').toLowerCase()}/profile-image`;
    console.warn('file object:', file)
    return {
        key: `${baseKey}.${extension}`,
        fileContentType: file.type,
        fileSize: file.size,
      };
  };
  
  const uploadClinicImage = async (selectedFile) => {
    if (!selectedFile) return;

    const fileObject = createFileObject(selectedFile);
    try {
        const response = await UserApi.createPresignedImagePutUrl(idToken, fileObject);
        if (response && response.putUrls) {
            const { presignedUrl, fields } = response.putUrls[0];
            const uploadedClinicImage = await UploadUtils.uploadFile(fields, selectedFile, presignedUrl);

            if (uploadedClinicImage) {
              assignClinicImageToUser(fileObject);
            }
        }
    } catch (error) {
        console.error("Failed to upload file:", error);
    } finally {
        setSelectedFile(null); // Optionally reset selected file state here
    }
  };
  
  const assignClinicImageToUser = async(fileObject) => {
    const payload = {
      firstName: clinicianData.userData.firstName,
      lastName: clinicianData.userData.lastName,
      emailAddress: clinicianData.userData.emailAddress,
      mobile: clinicianData.userData.mobile,
      clinicName: clinicianData.clinicData.clinicName,
      clinicNumber: clinicianData.clinicData.clinicNumber,
      clinicSize: clinicianData.clinicData.clinicSize,
      showLogoInHandouts: clinicianData.showLogoInHandouts,
      clinicImageId: fileObject.key,
    };
    console.warn('assignClinicImageToUser payload:', payload)
    await UserApi.updateClinicianData(authStore.idToken, payload);
    // update indexDB then reload view to show updated image
    fetchClinicianData();
  }

  const removeClinicImage = async(fileObject) => {
    console.warn('removeClinicImage');
    const payload = {
      firstName: clinicianData.userData.firstName,
      lastName: clinicianData.userData.lastName,
      emailAddress: clinicianData.userData.emailAddress,
      mobile: clinicianData.userData.mobile,
      clinicName: clinicianData.clinicData.clinicName,
      clinicNumber: clinicianData.clinicData.clinicNumber,
      clinicSize: clinicianData.clinicData.clinicSize,
      clinicImageId: '',
      showLogoInHandouts: clinicianData.showLogoInHandouts,
    };
    console.warn('removeClinicImage payload:', payload)
    await UserApi.updateClinicianData(authStore.idToken, payload);

    // reload view to show updated image
    fetchClinicianData();
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    
    // Unique case: handling combined Username field
    if (name === 'username') {
      const names = value.split(' ');
      const firstName = names[0] || '';
      const lastName = names.slice(1).join(' ') || '';
  
      setClinicianData((prevState) => ({
        ...prevState,
        userData: {
          ...prevState.userData,
          firstName,
          lastName,
        },
      }));
    } else {
      const [parentKey, childKey] = name.split('.');
  
      setClinicianData((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value,
        },
      }));
    }
    setHasUnsavedChanges(true);  
  };

  const updateEmail = async (newEmail, clinicDataPayload) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: newEmail });
      const clinicDataPayloadWithEmail = {...clinicDataPayload,emailAddress: newEmail }
      setPendingClinicDataUpdate(clinicDataPayloadWithEmail);
      setIsVerificationDialogOpen(true); 
    } catch (error) {
      console.error('Error updating email in Cognito:', error);
    }
  };

  const verifyEmailWithCode = async (verificationCode) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', verificationCode);
      console.log('Email verified successfully');
  
      if (pendingClinicDataUpdate) {
        // Perform the clinic data update after successful email verification
        await performClinicDataUpdate(pendingClinicDataUpdate);
        setPendingClinicDataUpdate(null); 
      }
  
      setIsVerificationDialogOpen(false);
      setIsSuccessEmailDialogOpen(true); 
    } catch (error) {
      console.error('Error verifying email:', error);
    }
  };
  
  const updateClinicData = async () => {  
    const clinicDataPayload = {
      firstName: clinicianData.userData.firstName,
      lastName: clinicianData.userData.lastName,
      emailAddress: clinicianData.userData.emailAddress,
      mobile: clinicianData.userData.mobile,
      clinicName: clinicianData.clinicData.clinicName,
      clinicNumber: clinicianData.clinicData.clinicNumber,
      clinicSize: clinicianData.clinicData.clinicSize,
      clinicImageId: clinicianData.clinicData.clinicImageId,
      showLogoInHandouts: clinicianData.showLogoInHandouts,
    };

    console.warn("payload", clinicDataPayload);
  
    if (newEmail !== clinicianData.userData.emailAddress) {
      await updateEmail(newEmail, clinicDataPayload);
    } else {
      await performClinicDataUpdate(clinicDataPayload);
      setHasUnsavedChanges(false);
    }
  };

  const performClinicDataUpdate = async (clinicDataPayload) => {
    try {
      const updatedData = await UserApi.updateClinicianData(authStore.idToken, clinicDataPayload);
      console.warn('Clinic data update API response:', updatedData);
      fetchClinicianData(); // Refresh clinician data
    } catch (error) {
      console.error('Failed to update clinic data:', error);
    }
  };
  
  const handleChangePasswordClick = async () => {
    const currentPassword = document.getElementById('current-password').value;
    const newPassword = document.getElementById('new-password').value;
    const confirmPassword = document.getElementById('confirm-password').value;
  
    setPasswordChangeError('');
  
    if (newPassword !== confirmPassword) {
      setPasswordChangeError("Please ensure confirm password matches.");
      return;
    }
  
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.signIn(user.username, currentPassword);
      await Auth.changePassword(user, currentPassword, newPassword);
      setIsChangePasswordDialogOpen(false); 
      setIsSuccessDialogOpen(true);
    } catch (error) {
      console.error('Error changing password:', error);
      if (error.code === 'NotAuthorizedException') {
        setPasswordChangeError('Current password is incorrect.');
      } else {
        setPasswordChangeError('Failed to change password. Ensure your new password is min 8 characters long with at least 1 number, uppercase and lowercase letter');
      }
    }
  };
  
  return (
    <CenteredWrapper component="main" sx={{zIndex: 1, position: 'relative'}}>
      {
        loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) 
        : 
        (
        <Box sx={{ gap: 0, width: '100%', maxWidth: 834}}>
          <Box>               
            <Box
              style={{
                height: '108px', 
                overflow: 'hidden',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundImage: `url(${clinicImage})`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                margin: '0 auto',
                marginBottom: '10px'
              }}
            >
              {
                !clinicImage && <img src={profilePlaceholder} alt='Logo Placeholder'/>
              }
            </Box>
            <Box style={{textAlign: 'center'}}>
              <Typography variant="h6">{clinicianData.clinicData.clinicName}</Typography>
              <Typography variant="subtitle2">{clinicianData.userData.emailAddress}</Typography>
            </Box>

            <Box style={{ textAlign: 'left', marginTop: '24px'}}>
              <Typography variant="h4" sx={{marginBottom: 5, fontSize: 32}}>Profile</Typography>
              <Divider sx={{marginBottom: 4}}/>
              <Typography variant="subtitle2" sx={{marginBottom: '4px'}}>Practice name</Typography>
              <TextField
                name="clinicData.clinicName"
                variant="outlined"
                value={clinicianData.clinicData.clinicName}
                onChange={handleChangeInput}
                size="small"
                fullWidth
                InputProps={{
                  style: { borderRadius: 12, maxWidth: 425, color: 'rgb(33, 33, 33)' },
                }}
                sx={{ input: { color: 'rgb(33, 33, 33)' }}}
              />

              <Typography variant="subtitle2" sx={{marginTop: '24px',marginBottom: '4px'}}>Practice phone number</Typography>
              <TextField
                name="clinicData.clinicNumber"
                variant="outlined"
                value={clinicianData.clinicData.clinicNumber}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  handleChangeInput({ target: { name: 'clinicData.clinicNumber', value: newValue } });
                }}
                size="small"
                fullWidth
                InputProps={{
                  style: { borderRadius: 12, maxWidth: 425, color: 'rgb(33, 33, 33)' },
                }}
                sx={{ input: { color: 'rgb(33, 33, 33)' }}}
              />

              <Typography variant="subtitle2" sx={{marginTop: '24px',marginBottom: '4px'}}>
                Practice size
              </Typography>
              <FormControl sx={{ width: isMobile ? '100%' : '425px' }}>
                <Select
                  labelId="practice-size-label"
                  id="practice-size-select"
                  name='clinicData.clinicSize'
                  value={clinicianData.clinicData.clinicSize || ''}
                  onChange={handleChangeInput}
                  sx={{
                    height: '40px',
                    marginTop: 0,
                    '& .MuiSelect-select': {
                      paddingRight: '32px', 
                    },
                  }}
                >
                  <MenuItem disabled value="">Please select practice size</MenuItem>
                  <MenuItem value="0-5">0-5 staff</MenuItem>
                  <MenuItem value="6-10">6-10 staff</MenuItem>
                  <MenuItem value="11-20">11-20 staff</MenuItem>
                  <MenuItem value="20+">20+ staff</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="subtitle2" style={{ marginTop: '24px', marginBottom: '4px'}}>First name</Typography>
              <TextField
                name="userData.firstName"
                variant="outlined"
                value={clinicianData.userData.firstName}
                onChange={handleChangeInput}
                size="small"
                fullWidth
                InputProps={{
                  style: { borderRadius: 12, maxWidth: 425, color: 'rgb(33, 33, 33)'},
                }}
                sx={{ input: { color: 'rgb(33, 33, 33)' }}}
              />

              <Typography variant="subtitle2" style={{ marginTop: '24px', marginBottom: '4px'}}>Last name</Typography>
              <TextField
                name="userData.lastName"
                variant="outlined"
                value={clinicianData.userData.lastName}
                onChange={handleChangeInput}
                size="small"
                fullWidth
                InputProps={{
                  style: { borderRadius: 12, maxWidth: 425, color: 'rgb(33, 33, 33)'},
                }}
                sx={{ input: { color: 'rgb(33, 33, 33)' }}}
              />

              <Typography variant="subtitle2" style={{ marginTop: '24px', marginBottom: '4px' }}>Email address</Typography>
              <TextField
                name="userData.emailAddress"
                variant="outlined"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                size="small"
                fullWidth
                InputProps={{
                  style: { borderRadius: 12, maxWidth: 425},
                }}
                sx={{ input: { color: 'rgb(33, 33, 33)' }}}
              />

              <Typography variant="subtitle2" style={{ marginTop: '24px', marginBottom: '4px' }}>Mobile phone number</Typography>
              <TextField
                name="userData.mobile"
                variant="outlined"
                value={clinicianData.userData.mobile}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  handleChangeInput({ target: { name: 'userData.mobile', value: newValue } });
                }}
                size="small"
                fullWidth
                InputProps={{
                  style: { borderRadius: 12, maxWidth: 425 },
                }}
                sx={{ input: { color: 'rgb(33, 33, 33)' } }}
              />

            </Box>

            <Divider sx={{marginTop:3, marginBottom: 5}}/>

            <Box style={{textAlign: 'left', marginTop: '24px'}}>
              <Typography variant="h6" sx={{marginBottom: 1}}>Practice logo</Typography>
              <Box display="flex" flexDirection='column'> 
                <Typography variant="caption" sx={{fontSize: 16, maxWidth: 560, marginBottom: 2}}>Upload your practice logo to add your branding to your patient’s information sheets. Image dimensions must not exceed maximum of 700x700 pixels.</Typography>
                {
                  (clinicImage ?
                    <img src={clinicImage} alt='Practice Logo' style={{ width: '115px',  }}/> :
                    <img src={profilePlaceholder} alt='Logo Placeholder' style={{ width: '115px', }}/>)
                }
              </Box>
              
              <Box component='section' display="flex" flexDirection='column' justifyContent="space-between" alignItems="flex-start" style={{ marginTop: 16, marginBottom: 16 }}>
                <input
                  id="file"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  ref={fileInputRef} 
                />
                <InputLabel htmlFor="file" onClick={() => fileInputRef.current.click()}>
                  <Box sx={{margin:'16px 0'}}>
                    <GradientBorderButton text={'Upload new logo'} image={uploadIcon} width={234} imageSize={17} />
                  </Box>
                </InputLabel>

                <Button variant='outlined' sx={{justifyContent: 'space-between', borderRadius: '36px', width: '234px', padding: '18px 16px', borderColor: '#616063', color:'#616063'}} onClick={() => removeClinicImage()}>
                  Delete logo 
                  <img src={binIcon} alt='Bin Icon' />
                </Button>
              </Box>
              <Typography variant="subtitle2" sx={{
                display: 'flex',
                flexDirection: { xs: 'row', sm: 'column' },
                alignItems: { xs: 'center', sm: 'flex-start'},
                justifyContent: 'space-between',
                margin: '8px 0',
                cursor: 'pointer',
              }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  Show logo on all the handouts?
                </span>
                <AntSwitch 
                  checked={clinicianData?.showLogoInHandouts || false}
                  onChange={(event) => {
                    setClinicianData({
                      ...clinicianData,
                      showLogoInHandouts: event.target.checked,
                    });
                  }} 
                />
              </Typography>
            </Box>

            <Divider sx={{marginTop:3, marginBottom: 5}}/>

            <Box style={{textAlign: 'left', marginTop: '24px'}}>
              <Typography variant="h6">Security</Typography>
              <Typography variant="subtitle2" style={{display: 'flex', justifyContent: 'space-between', margin: '8px 0', cursor: 'pointer', width: '234px'}} onClick={() => setIsChangePasswordDialogOpen(true)}>
                 <GradientBorderButton width={234} text={'Change password'} image={arrowRight} imageSize={18}/>        
              </Typography>
              <Typography variant="subtitle2" sx={{
                display: 'flex',
                flexDirection: { xs: 'row', sm: 'column' },
                alignItems: { xs: 'center', sm: 'flex-start'},
                justifyContent: 'space-between',
                margin: '8px 0',
                cursor: 'pointer',
              }}>
                  {/* <span style={{display: 'flex', alignItems: 'flex-start'}}>
                    Accept Cookies    
                  </span>
                  <AntSwitch  defaultChecked /> */}
              </Typography>

              <Button variant="outlined" color="primary" onClick={handleSignout} sx={{justifyContent: 'space-between', borderRadius: '36px', width: '234px', padding: '18px 16px', borderColor: '#616063', color:'#616063'}}>
                Sign out
              </Button>
            </Box>

            {/* <Divider sx={{marginTop:3, marginBottom: 5}}/> */}

            {/* <Box style={{textAlign: 'left', marginTop: '24px'}}>
              <Typography variant="h6">Subscription</Typography>
              <Typography variant="subtitle1">Update your current subscription here</Typography>
              <Typography variant="subtitle2" style={{display: 'flex', justifyContent: 'space-between', margin: '8px 0', cursor: 'pointer'}}>
                <GradientBorderButton text={'Update subscription'} image={arrowRight} imageSize={18}/>
              </Typography>
            </Box> */}
          </Box>
          
          <Box display="flex" flexDirection="column" gap={2}>
            <Button variant="contained" onClick={updateClinicData} sx={{'width': '100%', maxWidth: 234, 'marginTop': 10, backgroundColor: '#000453',  borderRadius: '36px', height: 61, position: 'relative'}} textAlign='left' justifyContent="flex-start">
              Save all changes <img src={arrowWhiteRight} alt='Right arrow' style={{position: 'absolute', right: 20}}/>
            </Button> 
          </Box>

          <Dialog open={isChangePasswordDialogOpen} onClose={() => setIsChangePasswordDialogOpen(false)}>
            <DialogTitle>Change password</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="current-password"
                label="Current Password"
                type="password"
                fullWidth
                variant="outlined"
                error={!!passwordChangeError}
              />
              <TextField
                margin="dense"
                id="new-password"
                label="New password"
                type="password"
                fullWidth
                variant="outlined"
              />
              <TextField
                margin="dense"
                id="confirm-password"
                label="Confirm new password"
                type="password"
                fullWidth
                variant="outlined"
              />
              {passwordChangeError && <Typography color="error" variant="body2">{passwordChangeError}</Typography>}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsChangePasswordDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleChangePasswordClick}>Change</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={isVerificationDialogOpen} onClose={() => setIsVerificationDialogOpen(false)}>
            <DialogTitle>Verify New Email</DialogTitle>
            <DialogContent>
              <DialogContentText>
                A verification code has been sent to your new email. Please enter the code below to verify your email change.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="verification-code"
                label="Verification Code"
                type="text"
                fullWidth
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsVerificationDialogOpen(false)}>Cancel</Button>
              <Button onClick={() => {
                const verificationCode = document.getElementById('verification-code').value;
                verifyEmailWithCode(verificationCode);
              }}>Verify Email</Button>
            </DialogActions>
          </Dialog>
          <SuccessDialog 
            open={isSuccessDialogOpen} 
            handleClose={() => setIsSuccessDialogOpen(false)}
            title="Password Changed"
            message="Your password has been successfully changed!"
          />
          <SuccessDialog 
            open={isSuccessEmailDialogOpen} 
            handleClose={() => setIsSuccessEmailDialogOpen(false)}
            title="Email updated"
            message="Your email has been successfully changed!"
          />
        </Box>
        )
      }
     <UnsavedChangesModal
        open={showPrompt}
        onDiscardChanges={onConfirmNavigation}
        onCancel={onCancelNavigation}
      />
    </CenteredWrapper>
  );
}

export const CATEGORIES = {
  "Body Area": [
    {
      name: "Abdomen and Pelvis",
      subcategories: ["Abdomen", "Pelvis", "Stomach", "Liver", "Kidneys", "Intestines", "Reproductive Organs"]
    },
    {
      name: "Cardiovascular System",
      subcategories: []
    },
    {
      name: "Head and Neck",
      subcategories: ["Skull", "Face", "Eyes", "Ears", "Nose", "Mouth", "Throat"]
    },
    {
      name: "Lower Limbs",
      subcategories: ["Hips", "Thigh", "Knee", "Lower Leg", "Ankle", "Foot"]
    },
    {
      name: "Pelvis",
      subcategories: ["Urinary System", "Reproductive Organs"]
    },
    {
      name: "Thorax",
      subcategories: ["Chest", "Back", "Spine"]
    },
    {
      name: "Upper Limb",
      subcategories: ["Shoulder", "Upper Arm", "Elbow", "Forearm", "Wrist", "Hand", "Fingers"]
    },
    {
      name: "Urinary System",
      subcategories: []
    }
  ],
  "Chronic conditions": [],
  "Diagrams": [],
  "General anatomy": [],
  "General principles": [],
  "Infectious disease": [],
  "Men's health": [],
  "Musculoskeletal": [],
  "Neurology": [],
  "Oncology": [],
  "Paediatrics": [],
  "Pulmonary and cardio": [],
  "Urology": [],
  "Women's health": []
};

export const CATEGORIES_VET = {
  "Animal": [
    {
      name: "Large Animals",
      subcategories: ["Equine", "Bovine", "Sheep"]
    },
    {
      name: "Small Animals",
      subcategories: ["Canine", "Feline", "Rabbit"]
    },
    {
      name: "Exotic Animals",
      subcategories: [
        {
          name: "Small mammals",
          subcategories: ["Guinea Pig", "Hamster", "Rat", "Mouse"]
        },
        {
          name: "Birds",
          subcategories: ["Chicken"]
        },
        {
          name: "Rabbit",
          subcategories: []
        },
        {
          name: "Reptiles",
          subcategories: ["Lizard", "Fish", "Snake", "Turtle", "Axolotl"]
        }
      ]
    }
  ],
  "Behaviour": [],
  "Dentistry": [],
  "Dermatology": [],
  "Diagrams": [],
  "Emergency": [],
  "Farm": [],
  "General anatomy": [],
  "General principles": [],
  "Internal medicine": [],
  "Neurology": [],
  "Opthalmology": [],
  "Orthopaedics": [],
  "Parasites": [],
  "Reproduction": [],
  "Surgery": []
};
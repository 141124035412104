import { makeAutoObservable, runInAction } from 'mobx';
import Dexie from 'dexie';
import StoreUtils from './store-utils';
import { toJS } from 'mobx'

export const UserDataState = Object.freeze({
  NO_DATA: 0,
  HAVE_NAME_AND_EMAIL: 1,
  HAVE_CLINIC_NAME: 2,
  HAVE_CLINIC_IMAGE: 4,
  DATA_SYNCED_TO_SERVER: 8,
});

class UserDataStore {
  userDataState = UserDataState.NO_DATA; // Start with no data
  userId = '';
  userData = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    mobile: '',
    clinicName: '',
    clinicNumber: '',
    clinicSize: '',
    clinicImageId: '', // Store base64 encoded image as string
    showLogoInHandouts: true,
    handouts: '[]'
  };
  lastUpdated = Date.now(); // Initialize with the current timestamp
  db;
  isInitialized = false;

  constructor(userId) {
    this.userId = userId;
    // Initialize Dexie
    this.db = StoreUtils.createDexieUserDB(userId, 'UserDataStore');
    this.db.version(1).stores({
      user: '&key', // Use '&key' for unique records
    });

    makeAutoObservable(this);
  }

  async initialize() {
    const userRecord = await this.db.user.get('userData');
    runInAction(() => {
      if (userRecord) {
        this.userDataState = userRecord.userDataState;
        this.userData = userRecord.userData;
        this.lastUpdated = userRecord.lastUpdated;
      }
      this.isInitialized = true;
    });
  }

  setUserData(userData) {
    const currentTime = Date.now();
    runInAction(() => {
      // Update individual fields and userDataState bitflags accordingly
      if (userData.firstName && userData.lastName && userData.emailAddress) {
        this.userDataState |= UserDataState.HAVE_NAME_AND_EMAIL;
      }
      if (userData.clinicName) {
        this.userDataState |= UserDataState.HAVE_CLINIC_NAME;
      }
      if (userData.clinicImageId) {
        this.userDataState |= UserDataState.HAVE_CLINIC_IMAGE;
      }
      console.warn('before userData', toJS(this.userData), this.userDataState);

      // Combine existing userData with the incoming userData
      this.userData = { ...this.userData, ...userData };
      console.warn('updated userData', toJS(this.userData), this.userDataState);
      this.lastUpdated = currentTime;
      
      // Update the single user record in IndexedDB
      this.db.user.put({
        key: 'userData',
        userId: this.userId, 
        userDataState: this.userDataState,
        userData: {...this.userData},
        lastUpdated: this.lastUpdated,
      });
    });
  }

  updateUserHandouts(handouts) {
    const currentTime = Date.now();
    runInAction(() => {
      // Update the handouts field
      this.userData.handouts = JSON.stringify(handouts)
  
      // Update the lastUpdated timestamp
      this.lastUpdated = currentTime;
  
      // Update the single user record in IndexedDB
      this.db.user.put({
        key: 'userData',
        userId: this.userId, 
        userDataState: this.userDataState,
        userData: {...this.userData},
        lastUpdated: this.lastUpdated,
      });
    });
  }
  
  getUserData() {
    // Return a copy to prevent direct modifications
    return {
      ...this.userData,
      userDataState: this.userDataState,
      lastUpdated: this.lastUpdated,
    };
  }

  async clearUserData() {
    await this.db.user.delete('userData');
    runInAction(() => {
      this.userDataState = UserDataState.NO_DATA;
      this.userData = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobile: '',
        clinicName: '',
        clinicNumber: '',
        clinicSize: '',
        clinicImageId: '', // Store base64 encoded image as string
        showLogoInHandouts: true,
        handouts: '[]'
      };
      this.lastUpdated = Date.now();
    });
  }

  async deleteDatabase() {
    await this.db.delete();
  }
}

const createUserDataStore = async (userId) => {
  const store = new UserDataStore(userId);
  await store.initialize(); // Wait for the store to be initialized
  return store; // Then return the initialized store
};

export default createUserDataStore;

import React, { useState, useEffect, useContext } from 'react';
import { Skeleton, Box, useMediaQuery, useTheme } from '@mui/material';
import ImageCacheContext from './image-cache-context';
import ImageIcon from '@mui/icons-material/Image';

function HandoutImageLoader({ src, alt, imageStore, ...props }) {
  const [loadedSrc, setLoadedSrc] = useState(null);
  const recentlyCachedUrls = useContext(ImageCacheContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' breakpoint typically corresponds to mobile views

  const desktopImageStyle = {
    maxWidth: '100%', // make sure the image is never wider than its container
    height: 'auto', // maintain aspect ratio
  };

  useEffect(() => {
    async function loadImage() {
      console.log(`HandoutImageLoader loadImage: ${src}`);

      // Retrieve the image data and the eTag from IndexedDB
      const imageObject = await imageStore.getImageFromIndexedDB(src);

      // Check if the image data was found
      if (imageObject && imageObject.data) {
        console.log(`HandoutImageLoader loadImage: ${src} found in IndexedDB`);
        setLoadedSrc(imageObject.data); // Set the loaded image source to the base64 data
      }
      // ...rest of the code, such as handling if the image was not found
    }

    loadImage();
  }, [src, imageStore, recentlyCachedUrls]);

  if (!loadedSrc) {
    return (
      <Box position="relative" display="inline-block" width={isMobile ? "100%" : 210} height={118}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <ImageIcon fontSize="large" color="disabled" />
        </Box>
      </Box>
    );
  }

  return (
    <img
      src={loadedSrc}
      alt={alt}
      style={isMobile ? { width: '100%', height: 'auto' } : desktopImageStyle}
      {...props}
    />
  );
}

export default HandoutImageLoader;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ListItem, ListItemText, Typography, Collapse, List, ButtonBase, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ContentListItem from '../search/content-list-item';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

const SubcategoryListItem = observer(({ subcategoryName, subcategoryData, parentPath, isExpanded, onToggleExpand, indentation }) => {
  console.warn('subcats', subcategoryData, subcategoryName, parentPath)
  const theme = useTheme();
  const hasChildren = Object.keys(subcategoryData.subcategories || {}).length > 0;
  const hasContent = subcategoryData.items && subcategoryData.items.length > 0;
  const currentPath = [...parentPath, subcategoryName]; // Construct the current path
  const { categoryStore } = useContext(StoreContext);
  // calculate the new indentation for nested categories
  const nestedIndentation = `${parseFloat(indentation.replace('em', '')) + 1}em`;

  const handleTrackHandout = async (contentName) => {
    console.warn("HandoutOpened: ", contentName)
    const currentUser = await Auth.currentAuthenticatedUser();

    mixpanel.track('HandoutOpened', {
      email: currentUser.attributes.email,
      handoutTitle: contentName
    });
  };

  // Construct breadcrumbs for each item
  const baseBreadcrumbs = ['SEARCH', process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'ANIMAL' : 'BODY AREA', ...parentPath, subcategoryName];

  return (
    <>
      <ListItem secondaryAction={<ButtonBase onClick={() => onToggleExpand(currentPath)}>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ButtonBase>} style={{ paddingLeft: indentation }}>
        <ListItemText primary={<ButtonBase onClick={() => onToggleExpand(currentPath)}><Typography variant="body1" style={{ fontWeight: 'bold', color: theme.palette.customColors.darkBlue }}>{subcategoryName}</Typography></ButtonBase>} />
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasContent && subcategoryData.items.map(content => (
            <ContentListItem 
              key={content.contentId} 
              breadcrumbs={baseBreadcrumbs} 
              contentName={content.contentName} 
              contentId={content.contentId} 
              indentation={nestedIndentation} 
              onHandoutSelect={handleTrackHandout}
            />
          ))}
          {hasChildren && Object.entries(subcategoryData.subcategories).map(([nestedSubcatName, nestedSubcatData]) => (
            <SubcategoryListItem 
              key={nestedSubcatName} 
              subcategoryName={nestedSubcatName} 
              subcategoryData={nestedSubcatData} 
              parentPath={currentPath} 
              isExpanded={categoryStore.isSubcategoryExpanded([...currentPath, nestedSubcatName])} 
              onToggleExpand={onToggleExpand} 
              indentation={nestedIndentation}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
});

export default SubcategoryListItem;

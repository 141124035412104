import React from 'react';
import { Box, useMediaQuery, useTheme, Button } from '@mui/material';
import pinkGradient from '../../assets/images/cetra-gradient_720.png';
import bodyAreaIcon from '../../assets/icons/categories/Body-Area-white.svg';
import conditionIcon from '../../assets/icons/categories/Condition-white.svg';
import animalIcon from '../../assets/icons/categories/Animal-white.svg';
import diagramsIcon from '../../assets/icons/categories/Diagrams-white.svg';

const categoryIconMap = {
    'Body area': bodyAreaIcon,
    'Condition': conditionIcon,
    'Animal': animalIcon,
    'Diagrams': diagramsIcon
};

export function CategoryButton({category, onClick, halfWidth}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const buttonStyle = {
    backgroundColor: theme.primaryBlue,
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'flex-end', 
    alignItems: 'flex-start', 
    padding: '16px', 
    height: '166px', 
    width: halfWidth ? '180px' : '387px', 
    borderRadius: '12px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.primaryBlue
    }
  };

  const buttonPinkGradientStyle = {
    backgroundImage: `url(${pinkGradient})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    color: 'white',
    '&:hover': {
      backgroundImage: `url(${pinkGradient})`
    }
  };

  const buttonWhiteStyle = {
    backgroundColor: 'white',
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    color: 'black',
    border: '4px solid #FC96A2',
    '&:hover': {
      backgroundColor: 'white'
    }
  };

  const iconCircleStyle = {
    position: 'absolute',
    top: 12, 
    right: 12, 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px', 
    height: '56px',
    borderRadius: '50%', 
    backgroundColor: 'rgba(255, 255, 255, 0.3)', 
    zIndex: 0,
  }

  const iconDiagramsCircleStyle = {
    position: 'absolute',
    top: 12, 
    right: 12, 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px', 
    height: '56px',
    borderRadius: '50%', 
    backgroundColor: 'rgba(252, 150, 162, 0.3)', 
    zIndex: 0,
  }

  return (
    <Button
      variant={category === 'Body area' || category === 'Animal' ? 'contained' : 'contained'}
      onClick={() => onClick(category)}
      sx={{
        marginX: category === 'Animal' || category === 'Body area' ? 2 : 0,
        ...buttonStyle,
        ...(category === 'Condition' ? buttonPinkGradientStyle : category === 'Diagrams' ? buttonWhiteStyle : {})
      }}
    >
      {category}
      <div style={category === 'Diagrams' ? iconDiagramsCircleStyle : iconCircleStyle}> 
        <img src={categoryIconMap[category]} alt="Category Icon" width="24px" height="24px" style={{
          position: 'relative',
          top: 0, 
          right: 0, 
          zIndex: 1
        }}/>
      </div>
    </Button>
  );
}

import React, { useState } from 'react';
import CustomToolbarButton from './toolbar/custom-toolbar-button';
import ASEmailIcon from '../../../assets/icons/Email.svg';
import ActionDialog from './action-dialog';
import { useGenerateLink} from './use-generated-link';
import { useSendEmail } from './use-send-email';
import ValidationUtils from '../../../react-core-lib/utils/validation-utils';
import { useTheme } from '@mui/material/styles';
import mixpanel from 'mixpanel-browser';

function HandoutEmailButton({ handoutData, currentUser, bundledHandoutsView = false }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const sendEmail = useSendEmail();
  const { generateLink } = useGenerateLink(handoutData, bundledHandoutsView);
  const theme = useTheme();

  const handleDialogSend = async (emailAddress) => {
    try {
      const linkId = await generateLink();

      await sendEmail(emailAddress, bundledHandoutsView ? 'your handouts' : handoutData.contentName, linkId);

      mixpanel.track('HandoutShared', {
        email: currentUser.attributes.email,
        shareMethod: 'Send Email',
        handoutTitle: handoutData.contentName
      });

    } catch (error) {
      console.error('Error while sending email:', error);
      throw error;
    }
  };

  return (
    <>
      <CustomToolbarButton iconSrc={ASEmailIcon} tooltipTitle="Email handout" onClick={() => setDialogOpen(true)} />
      <ActionDialog
        open={dialogOpen}
        title="Send via Email"
        placeholder="Email Address"
        onCancel={() => setDialogOpen(false)}
        onConfirm={handleDialogSend}
        validate={ValidationUtils.validateEmail}
        confirmButtonLabel="Send"
        errorTitle="Email Sending Error"
        errorMessage="There was a problem sending the email. Please try again."
        successTitle="Email Sent"
        successMessage="The email was sent successfully."
      />
    </>
  );
}

export default HandoutEmailButton;

import React from 'react';
import {
  Box,
  Typography,
  Divider
} from '@mui/material';
import { FinishProfileSetupCard } from './finish-profile-setup-card';
import { Feedback } from './feedback';
import { SearchHome } from './search-home';
import { useNavigate } from 'react-router-dom';
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import { CenteredBox } from '../../UIControls/centered-box';
import RecentlyViewedHandouts from '../handout/recently-viewed-handouts';
import FeaturedHandouts from '../handout/featured-handouts';

export function HomeScreen() {
  const navigate = useNavigate();

  return (
    <>
      <SearchHome />

      <FinishProfileSetupCard />

      <RecentlyViewedHandouts />

      <FeaturedHandouts />

      <CenteredWrapper>
        <Feedback />
      </CenteredWrapper>
    </>
  );
}

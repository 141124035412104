import Dexie from 'dexie';
import UserApi from '../rest-api/user-api';
import { makeObservable, action, observable } from 'mobx';
import axios from 'axios';
import StoreUtils from './store-utils';

export const StoreVersion = {
  INITIAL: 'INITIAL',
};

class ImageStore {
  version = StoreVersion.INITIAL;
  db;  // Will hold our Dexie database instance
  id = Date.now();

  constructor(userId) {
    console.log('ImageStore constructor id:', this.id);

    this.db = StoreUtils.createDexieUserDB(userId, 'ImageStore');

    this.db.version(1).stores({
      images: 'imageName, data, eTag'
    });

    makeObservable(this, {
      version: observable,
      migrate: action.bound,
    });
  }

  async clearStore() {
    console.log('Clearing IndexedDB ImageStore...');
    await this.db.images.clear();
  }

  async deleteDatabase() {
    console.log('Deleting IndexedDB database...');
    await this.db.delete();
  }

  // Save image and eTag to IndexedDB
  async saveImageToIndexedDB(imageName, imageData, eTag) {
    console.log(`ImageStore saveImageToIndexedDB: ${imageName}`);
    // Store the image data along with the eTag
    return this.db.images.put({ imageName, data: imageData, eTag });
  }

  // Retrieve image and eTag from IndexedDB
  async getImageFromIndexedDB(imageName) {
    console.log(`ImageStore getImageFromIndexedDB: ${imageName}`);
    // Retrieve both the image data and the eTag
    return this.db.images.get(imageName).then(imageObj => {
      if (imageObj) {
        // Return both data and eTag
        return { data: imageObj.data, eTag: imageObj.eTag };
      }
      return null;
    });
  }

  migrate() {
    console.log('ImageStore migrate');
    switch (this.version) {
      case StoreVersion.INITIAL:
        console.log('ImageStore migrate version is INITIAL');
        break;
      default:
        break;
    }
    // Perform migrations on the Dexie DB if necessary
  }

}

const createImageStore = async (userId) => {
  const store = new ImageStore(userId);
  /// await store.initialize(); // Wait for the store to be initialized
  return store; // Then return the initialized store
};

export default createImageStore;

import React from 'react';
import { Typography, Box, IconButton, Modal, List, useTheme, useMediaQuery, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentListItem from './content-list-item';
import { SearchFiltersVet } from './search-filters-vet';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

export const AlphabeticalSearchResults = ({ breadcrumbs, content, activeCategory, onBack, activeFilter, onFilterChange }) => {
  const theme = useTheme();
  const isTabletOrBelow = useMediaQuery(theme.breakpoints.down('md'));
  const overlayOpen = isTabletOrBelow && activeCategory;

  const handleTrackHandout = async (contentName) => {
    console.warn("HandoutOpened: ", contentName)
    const currentUser = await Auth.currentAuthenticatedUser();

    mixpanel.track('HandoutOpened', {
      email: currentUser.attributes.email,
      handoutTitle: contentName
    });
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: theme.zIndex.modal,
    overflow: 'auto',
    paddingTop: 4,
  };

  const letters = Object.keys(content);

  return (
    <>
      <Modal
        open={overlayOpen}
        onClose={onBack}
        aria-labelledby="alphabetical-search-results"
        aria-describedby="search-results-list"
      >
        <Box sx={overlayStyle}>
          <IconButton onClick={onBack} sx={{ position: 'absolute', top: 10, left: 4 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h4' marginTop={3} marginLeft={2} marginBottom={3}>
            {activeCategory}
          </Typography>            
          <Divider/>
          {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' && 
          (
          <Box marginTop={3} marginLeft={2}>
            Filter by 
            <SearchFiltersVet activeFilter={activeFilter} onFilterChange={onFilterChange}/>
          </Box>
          )}
          <List sx={{ marginTop: 2, paddingX: 2 }}>
            {letters.map((letter, idx) => (
              <React.Fragment key={letter}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  display="block"
                  gutterBottom
                  sx={{ mt: idx > 0 ? 4 : 0 }}
                >
                  {letter}
                </Typography>
                {content[letter].map((item) => (
                  <ContentListItem key={item.contentId} breadcrumbs={breadcrumbs} contentId={item.contentId} contentName={item.contentName} onHandoutSelect={handleTrackHandout}  />
                ))}
                <Divider/>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Modal>
      {!overlayOpen && (
        <List>
          {letters.map((letter, idx) => (
            <React.Fragment key={letter}>
              <Typography
                variant="h6"
                color="textSecondary"
                display="block"
                gutterBottom
                sx={{ mt: idx > 0 ? 4 : 0 }}
              >
                {letter}
              </Typography>
              {content[letter].map((item) => (
                <ContentListItem key={item.contentId} contentName={item.contentName} breadcrumbs={breadcrumbs} contentId={item.contentId} onHandoutSelect={handleTrackHandout} 
                />
              ))}
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
};

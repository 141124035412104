import React from 'react';
import { Box, Button } from '@mui/material';
import bodyAreaIcon from '../../../assets/icons/categories/Body-Area.svg';
import chronicConditionsIcon from '../../../assets/icons/categories/Chronic-conditions.svg';
import diagramsIcon from '../../../assets/icons/categories/Diagrams.svg';
import generalAnatomy from '../../../assets/icons/categories/General-anatomy.svg';
import generalAnatomyAnimal from '../../../assets/icons/categories/General-anatomy-animal.svg';
import generalPrinciples from '../../../assets/icons/categories/General-principles.svg';
import generalPrinciplesAnimal from '../../../assets/icons/categories/General-principles-animal.svg';
import neurologyIcon from '../../../assets/icons/categories/Neurology.svg';
import womensHealthIcon from '../../../assets/icons/categories/Womens-health.svg';
import animalIcon from '../../../assets/icons/categories/Animal.svg';
import behaviourIcon from '../../../assets/icons/categories/Behaviour.svg';
import chickenIcon from '../../../assets/icons/categories/Chicken.svg';
import denistryIcon from '../../../assets/icons/categories/Denistry.svg';
import dermatologyIcon from '../../../assets/icons/categories/Dermatology.svg';
import emergencyIcon from '../../../assets/icons/categories/Emergency.svg';
import farmIcon from '../../../assets/icons/categories/Farm.svg';
import infectiousDiseaseIcon from '../../../assets/icons/categories/Infectious-disease.svg';
import internalMedicineIcon from '../../../assets/icons/categories/Internal-medicine.svg';
import oncologyIcon from '../../../assets/icons/categories/Oncology.svg';
import urologyIcon from '../../../assets/icons/categories/Urology.svg';
import musculoskeletalIcon from '../../../assets/icons/categories/Musculoskeletal.svg';
import preventativeIcon from '../../../assets/icons/categories/Preventative.svg';
import parasitesIcon from '../../../assets/icons/categories/Parasites.svg';
import reproductionIcon from '../../../assets/icons/categories/Reproduction.svg';
import surgeryIcon from '../../../assets/icons/categories/Surgery.svg';
import orthopaedicIcon from '../../../assets/icons/categories/Orthopaedic.svg';
import opthamologyIcon from '../../../assets/icons/categories/Opthamology.svg';

import { observer } from 'mobx-react-lite';

const categoryIconMap = {
  'Chronic conditions': chronicConditionsIcon,
  'Chicken': chickenIcon,
  'Dentistry': denistryIcon,
  'Dermatology': dermatologyIcon,
  'General anatomy': process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalAnatomyAnimal : generalAnatomy,
  'General principles': process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalPrinciplesAnimal : generalPrinciples,
  'Infectious disease': infectiousDiseaseIcon,
  'Oncology': oncologyIcon,
  'Urology': urologyIcon, 
  "Women's health": womensHealthIcon,
  'Neurology': neurologyIcon,
  'Behaviour': behaviourIcon,
  'Emergency': emergencyIcon,
  'Farm': farmIcon,
  'Internal medicine': internalMedicineIcon,
  'Musculoskeletal': musculoskeletalIcon,
  'Orthopaedics': orthopaedicIcon,
  'Opthamology': opthamologyIcon,
  'Preventative': preventativeIcon,
  'Parasites': parasitesIcon,
  'Reproduction': reproductionIcon,
  'Surgery': surgeryIcon,
};

export const SearchCategories = observer(({categories, activeCategory, onCategoryChange }) => {
  const handleCategoryClick = (category, event) => {
    event.preventDefault(); 
    if (activeCategory === category){onCategoryChange(null)} 
    else {onCategoryChange(category)};
  };
  return (
    <Box sx={{marginTop: '8px!important'}}>
      {Object.entries(categories).map(([category, icon]) => (
        <Button
          key={category}
          className='category-btn'
          sx={{
            color: activeCategory === category ? 'white' : 'black',
            backgroundColor: activeCategory === category ? '#FC96A2' : '#f7f7f7',
            borderRadius: '16px',
            marginRight: 1,
            marginTop: 1,
            height: 48,
            fontSize: 12,
            '&:hover': {
              backgroundColor: activeCategory === category ? '#FC96A2' : '#f7f7f7',
            },
          }}
          onClick={(e) => handleCategoryClick(category, e)}
          onTouchEnd={(e) => handleCategoryClick(category, e)}
        >
          <img
            src={categoryIconMap[category] ? categoryIconMap[category] : process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? animalIcon : bodyAreaIcon}
            alt={`${category} Category Icon`}
            width="24px"
            height="24px"
            style={{
              marginRight: 4,
              position: 'relative',
              top: 0,
              right: 0,
              zIndex: 1,
              filter: activeCategory === category ? 'invert(100%)' : 'initial',
            }}
          />
          {category}
        </Button>
      ))}
    </Box>
  );
});


import React, { useContext, useEffect, useState, useRef } from 'react';
import { Typography, Container, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import StoreContext from "../../../react-core-lib/components/app/store-context";
import HandoutPreview from './handout-preview';
import UserApi from '../../../rest-api/user-api';
import DownloadUtils from '../../../utils/download-utils';

const MAX_RECENT_HANDOUTS = 5;

function FeaturedHandouts() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelow900 = useMediaQuery(theme.breakpoints.down('md'));
  const isBelow1200 = useMediaQuery(theme.breakpoints.down('lg'));

  const { contentStore, imageStore, authStore } = useContext(StoreContext);
  const [featuredHandouts, setFeaturedHandouts] = useState([]);
  const navigationPrevRef = useRef(null); 
  const navigationNextRef = useRef(null);
  const [swiper, setSwiper] = useState(null); 

  useEffect(() => {
    async function fetchAndStoreImages(handouts) {
      const promises = handouts.map(async handout => {
        const locale = 'en-UK';
        const idToken = authStore.idToken;
        const localeData = handout.contentData[locale] || [];
        const introductionSection = localeData.find(section => section.sectionName === "introduction") || { blocks: [] };
        const firstIllustration = introductionSection.blocks.find(block => block.type === 'illustration');
        
        if (firstIllustration) {
          const imageSrc = firstIllustration.src;
          const imageObject = await imageStore.getImageFromIndexedDB(imageSrc);
          console.warn(imageSrc, imageObject);
          if (!imageObject) {
            try {
              const payload = {
                files: [{method: "get", key: imageSrc}],
                contentCategory: "image"
              };
              // Get presigned URL for the image
              const presignedUrl = await UserApi.generatedPresignedUrls(idToken, payload);
              console.warn('new image to downlaod..' , presignedUrl)
              // Download the image using the presigned URL
              const downloadResult = await DownloadUtils.downloadImageFromPresignedUrl(presignedUrl.getUrls[0]['presignedUrl']);
              // If the download was successful, save the downloaded image to IndexedDB
              console.warn(downloadResult);
              if (downloadResult) {
                const base64data = downloadResult.base64data;
                const etag = downloadResult.etag
                await imageStore.saveImageToIndexedDB(imageSrc, base64data, etag);
                // fetchAndStoreImages()
              } else {
                console.error(`Failed to download image: ${downloadResult.reason}`);
              }
            } catch (error) {
              console.error(`Error fetching image: ${error}`);
            }
          }
        }
      });
    
      await Promise.all(promises);
      setFeaturedHandouts(handouts);
    }
    
    const fetchedHandouts = contentStore.getFeaturedContent();

    fetchAndStoreImages(fetchedHandouts);
  }, [contentStore, imageStore]);


  if (featuredHandouts.length === 0) {
    return null;
  }

  return (
    <Container component={'section'} maxWidth={isMobile ? 'md' : 'xl'} sx={{ position: 'relative', pt: 4 }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ mb: 2 }}>
        Popular handouts
      </Typography>

      <Swiper
        modules={[Navigation]}
        onSwiper={setSwiper}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        loop={true}
        spaceBetween={10}
        slidesPerView={isMobile ? 1 : isBelow900 ? 2 : isBelow1200 ? 3 : 4}
      >
        {featuredHandouts.map(handoutData => (
          <SwiperSlide key={handoutData.contentId}>
            <HandoutPreview handoutData={handoutData} />
          </SwiperSlide>
        ))}
      </Swiper>

      <IconButton
        ref={navigationPrevRef}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: 0,
          zIndex: 10,
          color: '#000453',
          backgroundColor: '#DFDCEA',
          '&:hover': {
            backgroundColor: '#c4c1d7',
          },
        }}
      >
        <ChevronLeftRoundedIcon />
      </IconButton>
      <IconButton
        ref={navigationNextRef}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: 0,
          zIndex: 10,
          color: '#000453',
          backgroundColor: '#DFDCEA',
          '&:hover': {
            backgroundColor: '#c4c1d7',
          },
        }}
      >
        <ChevronRightRoundedIcon />
      </IconButton>
    </Container>
  );
}

export default FeaturedHandouts;

import { makeObservable, action, observable } from 'mobx';

class SearchStore {
  searchTerm = '';
  rawSearchTerm = ''; // Add this line to declare the observable
  searchResults = [];

  constructor() {
    console.log('SearchStore constructor');

    makeObservable(this, {
      searchTerm: observable,
      rawSearchTerm: observable, // Make rawSearchTerm observable
      searchResults: observable,
      setSearchTerm: action.bound,
      setRawSearchTerm: action.bound, 
      setSearchResults: action.bound
    });
  }

  setRawSearchTerm(term) {
    this.rawSearchTerm = term;
  }

  setSearchTerm(term) {
    this.searchTerm = term.trim(); // Trim the term before setting it
  }

  setSearchResults(results) {
    this.searchResults = results;
  }
}

const createSearchStore = async (userId) => {
  const store = new SearchStore();
  return store; // Then return the initialized store
};

export default createSearchStore;
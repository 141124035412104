import React, { useContext, useRef, useState, useEffect } from 'react';
import { TextField, Button, Box, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { SearchResults } from './search-results';
import { CategoryButton } from '../../UIControls/category-button';
import { SearchFiltersVet } from './search-filters-vet';
import { SearchCategories } from './search-categories';

export const SearchControls = observer(({
  searchTerm,
  onSearchChange,
  activeFilter,
  onFilterChange,
  activeCategory,
  onCategoryChange,
  disableSearch = false
}) => {
  const navigate = useNavigate();
  const { contentStore, searchStore } = useContext(StoreContext);
  const searchInputRef = useRef(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [categories, setCategories] = useState({});

  useEffect(() => {
    // Fetch categories once the store is initialized
    if (contentStore.isInitialized) {
      const filteredData = Object.fromEntries(
        Object.entries(contentStore.getCategoriesData()).filter(([key, _]) => key !== 'Body Area' && key !== 'Animal' && key !== 'Diagrams')
      );
      setCategories(filteredData);
    }
  }, [contentStore]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      setTimeout(() => {
        if (!searchInputRef.current?.contains(document.activeElement)) {
          setDropdownVisible(false);
        }
      }, 200);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {document.removeEventListener('mousedown', handleClickOutside)};
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchTerm.trim()) {
        searchStore.setSearchTerm(searchTerm.trim());
        const results = contentStore.searchContentMeta(searchTerm.trim());
        searchStore.setSearchResults(results);
        setDropdownVisible(true); 
      } else {
        searchStore.setSearchTerm('');
        searchStore.setSearchResults([]);
        setDropdownVisible(false); 
      }
    }, 200); 

    return () => clearTimeout(timerId);
  }, [searchTerm, contentStore, searchStore]);

  const handleSearchInputChange = (e) => {
    onSearchChange(e.target.value);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!searchInputRef.current?.contains(document.activeElement)) {
        setDropdownVisible(false);
      }
    }, 200); 
  };

  const handleButtonClick = (filterType) => {
    if (filterType === 'Condition') {
      navigate('/search', { state: { filter: 'Condition' } });
    } else if (filterType === 'Body area' || filterType === 'Animal') {
      navigate('/search/categories', { state: { filter: filterType } });
    } else if (filterType === 'Diagrams') {
      navigate('/search/diagrams', { state: { category: 'Diagrams' } });
    }
  };

  const handleSearchIconClick = () => {
    setDropdownVisible(true);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 0)
  };

  return (
    <Stack spacing={2} width="100%" position={'relative'}>
      <Box display="flex" alignItems="center" width="100%">
        <TextField
          fullWidth
          value={searchTerm}
          onChange={handleSearchInputChange}
          onBlur={handleBlur}
          onFocus={() => searchTerm && searchStore.searchResults.length && setDropdownVisible(true)}
          variant="outlined"
          placeholder="Search for a condition..."
          InputProps={{
            endAdornment: (
              <Button onClick={() => handleSearchIconClick()}>
                <SearchIcon />
              </Button>
            ),
          }}
          sx={{
            ...disableSearch ? { backgroundColor: '#f0f0f0', '.MuiOutlinedInput-notchedOutline': { borderColor: '#d0d0d0' }, '&.Mui-disabled': { WebkitTextFillColor: '#a0a0a0', color: '#a0a0a0' } } : {},
            borderRadius: searchTerm ? '12px' : '0px',
          }} 
          inputRef={searchInputRef}     
        />
        {isDropdownVisible && <SearchResults breadcrumbs={['SEARCH']} results={searchStore.searchResults} popup={true} />} 
      </Box>
      {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' && 
      (
        <>
          <Box display="flex" alignItems="center" width="100%">
          Filter by
          </Box>
          <SearchFiltersVet activeFilter={activeFilter} onFilterChange={onFilterChange} />
        </>
      )}
      <Box display="flex" alignItems="center" width="100%">
        Browse by
      </Box>
      <Box display="flex" justifyContent="space-between">
        <CategoryButton category={'Condition'} onClick={() => handleButtonClick('Condition')}/>
        <CategoryButton category={process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'Animal' : 'Body area'} onClick={() => handleButtonClick(process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'Animal' : 'Body area')}/>
        <CategoryButton category={'Diagrams'} onClick={() => handleButtonClick('Diagrams')}/>
      </Box>
      {categories && Object.keys(categories).length > 0 && (
        <Box display="flex" alignItems="center" width="100%">
          Categories
        </Box>
      )}
      <SearchCategories categories={categories} activeCategory={activeCategory} onCategoryChange={onCategoryChange}/>
    </Stack>
  );
});
